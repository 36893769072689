<template>
  <div class="w-100 vh-100">
    <div
      class="bg-color-white h-100 w-100 d-flex justify-content-center align-items-center"
    >
      <div class="custom-container">
        <div class="custom-heading font-butler color-black mb-1">
          Check your mail
        </div>
        <p>
          An email with verificataion instructions was sent to
          <span class="color-black">{{ email }}</span> . If you don’t see the
          email in your inbox, remember to try your smam folder too. Once you
          verify your email address, you can get started with listing your
          property.
        </p>
        <div class="mt-2">
          <div class="custom-subheading font-butler color-black">
            Didn't get an email?
          </div>
          <router-link to="/" class="custom-link color-blue">
            Resend Verification Link
          </router-link>
        </div>
        <div class="mt-2">
          <div class="custom-subheading font-butler color-black">
            Entered the Wrong Email?
          </div>
          <router-link to="/" class="custom-link color-blue">
            Reenter your email
          </router-link>
        </div>
        <p class="mt-2">
          If you aren’t getting email don’t hesitate to
          <router-link to="/" class="color-blue">contact support.</router-link>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: "xyz123@mail.com",
    };
  },
};
</script>
<style lang="scss" scoped>
.custom-container {
  width: 40%;
  height: 60%;
  .custom-heading {
    font-size: 2.3rem;
    font-weight: 500;
  }
  p {
    font-size: 1.2rem;
    span {
      font-weight: 700;
    }
  }
  .custom-subheading {
    font-size: 1.5rem;
    margin-bottom: 0.3rem;
  }
  .custom-link {
    font-size: 1.2rem;
  }
}
</style>
